import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WebResponse } from '../web-response';
import 'rxjs/add/operator/map';
import { ConfigService } from '../config/config-service';
import { Observable , throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { ConsentRevoke } from 'src/app/services/consent/consent-summary';
import { WebHeader } from '../web-header';
import { WebRequest } from '../web-request';

@Injectable({
  providedIn: 'root'
})
export class ConsentService {

  apiBaseUrl: string;

  constructor(private http: HttpClient,
    public configService: ConfigService) {
    this.apiBaseUrl = this.configService.apiBaseUrl;
  }

  getConsentDetailList(customerId){
    var response = this.http.get<WebResponse>(this.apiBaseUrl + '/consent-list/' + customerId)

       response.map((res: WebResponse) => res.header).subscribe(data =>{
        var header = [];
        header = Object.keys(data).map(e => data[e]);
        localStorage.setItem("messageID", header[0]);
      })
      
    return response.map((res: WebResponse) => res.body).pipe(retry(1),
    catchError(this.handleError));
  }

  consentRevoke(consentRevoke:ConsentRevoke): Observable<ConsentRevoke>{
    const header: WebHeader = {
      rid: localStorage.getItem("messageID"),
      ts: new Date(),
    };
    
    const request: WebRequest = {
      header: header,
      body: consentRevoke
    };
    
    return this.http.post<ConsentRevoke>(this.apiBaseUrl +'/consent-revoke', request).pipe(retry(1),
    catchError(this.handleError));
  }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    if(error.status == 401){
      console.log("JWT expired!");
      window.location.href = '/login'
    }
    if(error.status == 400){
      throwError(error.message);
    }
    return throwError(errorMessage);
   }
}
