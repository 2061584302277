import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WebResponse } from '../web-response';
import { WebRequest } from '../web-request';
import { CreateAggregatorSummary, UpdateAggregatorSummary, ApprovalAggregatorSummary, AggregatorSummary } from 'src/app/services/aggregators/aggregator-summary'
import 'rxjs/add/operator/map';
import { WebHeader } from '../web-header';
import { ConfigService } from '../../services/config/config-service';

@Injectable({
  providedIn: 'root'
})
export class AggregatorService {

  apiBaseUrl: string;

  constructor(private http: HttpClient,
    public configService: ConfigService) {
    this.apiBaseUrl = this.configService.apiBaseUrl;
  }

  fetchAAAipStatistic(){
    var response = this.http.get<WebResponse>(this.apiBaseUrl + '/aa-statistic');
    response.map((res: WebResponse) => res.header).subscribe(data =>{
      var header = [];
      header = Object.keys(data).map(e => data[e]);
      localStorage.setItem("messageID", header[0]);
    }) 
    return response.map((res: WebResponse) => res.body);
  }

  getAggregatorsList() {
    var response = this.http.get<WebResponse>(this.apiBaseUrl + '/aggregators-list');

    response.map((res: WebResponse) => res.header).subscribe(data => {
      var header = [];
      header = Object.keys(data).map(e => data[e]);
      localStorage.setItem("messageID", header[0]);
    })

    console.log("list of aggregators : " + JSON.stringify(response));


    return response.map((res: WebResponse) => res.body);
  }

  getAggregator(aaId) {
    var response = this.http.get<WebResponse>(this.apiBaseUrl + '/get-aggregator?aggregatorId=' + aaId);

    response.map((res: WebResponse) => res.header).subscribe(data => {
      var header = [];
      header = Object.keys(data).map(e => data[e]);
      localStorage.setItem("messageID", header[0]);
    })

    return response.map((res: WebResponse) => res.body);
  }

  createAggregator(createAggregatorSummary: CreateAggregatorSummary) {

    const header: WebHeader = {
      rid: localStorage.getItem("messageID"),
      ts: new Date()
    };

    const request: WebRequest = {
      header: header,
      body: createAggregatorSummary
    };

    var response = this.http.post<WebResponse>(this.apiBaseUrl + '/create-aggregator', request);

    response.map((res: WebResponse) => res.header).subscribe(data => {
      var header = [];
      header = Object.keys(data).map(e => data[e]);
      localStorage.setItem("messageID", header[0]);
    })

    return response.map((res: WebResponse) => res.body);

  }

  deleteAggregator(aaId) {
    var response = this.http.delete<WebResponse>(this.apiBaseUrl + '/delete-aggregator?aggregatorId=' + aaId);

    response.map((res: WebResponse) => res.header).subscribe(data => {
      var header = [];
      header = Object.keys(data).map(e => data[e]);
      localStorage.setItem("messageID", header[0]);
    })

    return response.map((res: WebResponse) => res.body);
  }

  updateAggregator(updateAggregatorSummary: UpdateAggregatorSummary) {

    const header: WebHeader = {
      rid: localStorage.getItem("messageID"),
      ts: new Date()
    };

    const request: WebRequest = {
      header: header,
      body: updateAggregatorSummary
    };

    var response = this.http.put<WebResponse>(this.apiBaseUrl + '/update-aggregator', request);

    response.map((res: WebResponse) => res.header).subscribe(data => {
      var header = [];
      header = Object.keys(data).map(e => data[e]);
      localStorage.setItem("messageID", header[0]);
    })

    return response.map((res: WebResponse) => res.body);
  }

  approvalAggregator(approvalAggregatorSummary: ApprovalAggregatorSummary) {

    const header: WebHeader = {
      rid: localStorage.getItem("messageID"),
      ts: new Date()
    };

    const request: WebRequest = {
      header: header,
      body: approvalAggregatorSummary
    };

    var response = this.http.put<WebResponse>(this.apiBaseUrl + '/update-aggregator', request);

    response.map((res: WebResponse) => res.header).subscribe(data => {
      var header = [];
      header = Object.keys(data).map(e => data[e]);
      localStorage.setItem("messageID", header[0]);
    })

    return response.map((res: WebResponse) => res.body);
  }


}
