import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WebResponse } from '../web-response';
import { WebHeader } from '../web-header';
import { WebRequest } from '../web-request';
import { FipDetailsSummary } from 'src/app/services/fip-details/fip-details-summary';
import 'rxjs/add/operator/map';
import { ConfigService } from '../../services/config/config-service';

@Injectable({
  providedIn: 'root'
})

export class FipDetailsSummaryService {

  apiBaseUrl: string;

  constructor(private http: HttpClient,
    public configService: ConfigService) {
    this.apiBaseUrl = this.configService.apiBaseUrl;
  }

  fetchFIPAipStatistic(){
    var response = this.http.get<WebResponse>(this.apiBaseUrl + '/fip-statistic');
    response.map((res: WebResponse) => res.header).subscribe(data =>{
      var header = [];
      header = Object.keys(data).map(e => data[e]);
      localStorage.setItem("messageID", header[0]);
    }) 
    return response.map((res: WebResponse) => res.body);
  }

  getFipDetails()
  {
    var response = this.http.get<WebResponse>(this.apiBaseUrl + '/fip-profile');
    response.map((res: WebResponse) => res.header).subscribe(data =>{
      var header = [];
      header = Object.keys(data).map(e => data[e]);
      localStorage.setItem("messageID", header[0]);
    }) 
    return response.map((res: WebResponse) => res.body);
  }

  createFipProfile(fipDetailsSummary:FipDetailsSummary)
  {
    const header: WebHeader = {
      rid: localStorage.getItem("messageID"),
      ts: new Date()
    };

    const request: WebRequest = {
      header: header,
      body: fipDetailsSummary
    };
    
      var response = this.http.post<WebResponse>(this.apiBaseUrl + '/insert-fip-profile', request);
      response.map((res: WebResponse) => res.header).subscribe(data =>{
      var header = [];
      header = Object.keys(data).map(e => data[e]);
      localStorage.setItem("messageID", header[0]);
    })
    return response.map((res: WebResponse) => res.body);
  }

  createTempFipDetails(fipDetailsSummary:FipDetailsSummary){
    const header: WebHeader = {
      rid: localStorage.getItem("messageID"),
      ts: new Date()
    };

    const request: WebRequest = {
      header: header,
      body: fipDetailsSummary
    };

    var response = this.http.post<WebResponse>(this.apiBaseUrl + '/create-fip-profile', request);
    response.map((res: WebResponse) => res.header).subscribe(data =>{
      var header = [];
      header = Object.keys(data).map(e => data[e]);
      localStorage.setItem("messageID", header[0]);
    })
    return response.map((res: WebResponse) => res.body);
  }

  updateFipDetails(fipDetailsSummary:FipDetailsSummary){

    const header: WebHeader = {
      rid: localStorage.getItem("messageID"),
      ts: new Date()
    };

    const request: WebRequest = {
      header: header,
      body: fipDetailsSummary
    };

     var response = this.http.put<WebResponse>(this.apiBaseUrl + '/update-fip-profile', request);
     response.map((res: WebResponse) => res.header).subscribe(data =>{
      var header = [];
      header = Object.keys(data).map(e => data[e]);
      localStorage.setItem("messageID", header[0]);
    })
    return response.map((res: WebResponse) => res.body);
  }
}


