import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WebResponse } from '../web-response';
import 'rxjs/add/operator/map';
import { WebRequest } from '../web-request';
import {CreatedUserRolesSummary, UpdateUserRolesSummary , ApprovalUserRolesSummary} from './user-roles-summary'
import { WebHeader } from '../web-header';
import { ConfigService } from '../config/config-service';

@Injectable({
  providedIn: 'root'
})
export class UserRolesService {

  apiBaseUrl: string;

  constructor(private http: HttpClient,
    public configService: ConfigService) {
    this.apiBaseUrl = this.configService.apiBaseUrl;
  }

  getUserRolesList(){
    var response =  this.http.get<WebResponse>(this.apiBaseUrl + '/userroles-list');

      response.map((res: WebResponse) => res.header).subscribe(data =>{
        var header = [];
        header = Object.keys(data).map(e => data[e]);
        localStorage.setItem("messageID", header[0]);
      })
      
    return response.map((res: WebResponse) => res.body);
  }

  getUserRole(roleId){
    var response = this.http.get<WebResponse>(this.apiBaseUrl + '/get-userrole?roleId='+roleId);

    response.map((res: WebResponse) => res.header).subscribe(data =>{
      var header = [];
      header = Object.keys(data).map(e => data[e]);
      localStorage.setItem("messageID", header[0]);
    })
    
    return response.map((res: WebResponse) => res.body);
  }

  createUserRoles(createdUserRolesSummary: CreatedUserRolesSummary){

    const header: WebHeader = {
      rid: localStorage.getItem("messageID"),
      ts: new Date()
    };

    const request: WebRequest = {
      header: header,
      body: createdUserRolesSummary
    };

    var response = this.http.post<WebResponse>(this.apiBaseUrl + '/create-userroles', request);

      response.map((res: WebResponse) => res.header).subscribe(data =>{
        var header = [];
        header = Object.keys(data).map(e => data[e]);
        localStorage.setItem("messageID", header[0]);
      })
      
    return response.map((res: WebResponse) => res.body);
  }

  deleteUserRoles(roleId){
    var response = this.http.delete<WebResponse>(this.apiBaseUrl + '/delete-userroles?roleId='+ roleId );

       response.map((res: WebResponse) => res.header).subscribe(data =>{
        var header = [];
        header = Object.keys(data).map(e => data[e]);
        localStorage.setItem("messageID", header[0]);
      })
      
    return response.map((res: WebResponse) => res.body);
  }

  updateUserRoles(updateUserRolesSummary: UpdateUserRolesSummary){

    const header: WebHeader = {
      rid: localStorage.getItem("messageID"),
      ts: new Date()
    };

    const request: WebRequest = {
      header: header,
      body: updateUserRolesSummary
    };

    var response = this.http.put<WebResponse>(this.apiBaseUrl + '/update-userroles', request);

      response.map((res: WebResponse) => res.header).subscribe(data =>{
        var header = [];
        header = Object.keys(data).map(e => data[e]);
        localStorage.setItem("messageID", header[0]);
      })
      
    return response.map((res: WebResponse) => res.body);
  }

  approvalUserRoles(approvalUserRolesSummary: ApprovalUserRolesSummary){

    const header: WebHeader = {
      rid: localStorage.getItem("messageID"),
      ts: new Date()
    };

    const request: WebRequest = {
      header: header,
      body: approvalUserRolesSummary
    };

    var response = this.http.put<WebResponse>(this.apiBaseUrl + '/update-userroles', request);

      response.map((res: WebResponse) => res.header).subscribe(data =>{
        var header = [];
        header = Object.keys(data).map(e => data[e]);
        localStorage.setItem("messageID", header[0]);
      })
      
    return response.map((res: WebResponse) => res.body);
  }
}